import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import SEO from '../components/organisms/seo';
import RichText from '../components/molecules/richtext';

const CodeOfConductPage = ({ data }) => {
  const { title, _rawContent } = data.codeofconduct.nodes[0];

  return (
    <div className="spacing-top-big">
      <SEO siteTitle={title} />
      <div className="legal grid">
        <p className="title">{title}</p>
        <RichText blocks={_rawContent} />
      </div>
    </div>
  );
};

export const query = graphql`
  query CodeOfConduct {
    codeofconduct: allSanityCodeofconduct {
      nodes {
        title
        _rawContent
      }
    }
  }
`;

CodeOfConductPage.propTypes = {
  data: PropTypes.object,
};

export default CodeOfConductPage;
